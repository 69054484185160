import { JL } from 'jsnlog';
import {nanoid} from "nanoid";

function getOption(name, dflt = null) {
    const params = new URLSearchParams(window.location.search);
    let value = '';

    if (params.has(name)) {
        let trace = params.get(name);
        if (window.sessionStorage) {
            if (trace && trace.length > 0) {
                window.sessionStorage.setItem(name, trace);
            } else {
                window.sessionStorage.removeItem(name);
            }
        }
    }

    value = window.sessionStorage.getItem(name);
    if (!value || value.length === 0) {
        if (typeof dflt === 'function') {
            value = dflt();
        } else {
            value = dflt;
        }
    }
    return value;
}

function getRequestId() {
    return getOption('_trace', nanoid);
}

function collectAll() {
    return getOption('_trace', false) !== false;
}

const doCollectAll = collectAll();

const serverAppenderDefaults = {
    'bufferSize': 500,
    'storeInBufferLevel': JL.getAllLevel(),
    'level': doCollectAll ? JL.getAllLevel() : JL.getErrorLevel(),
    'sendWithBufferLevel': JL.getAllLevel()
};

const loggerDefaults = {
    'level': JL.getAllLevel()
};

const consoleAppender = JL.createConsoleAppender('Console');

consoleAppender.setOptions({
    level: JL.getAllLevel()
});

const appenders = [consoleAppender];

if (!window.tellMe.disabled || doCollectAll  ) {
    const serverAppender = JL.createAjaxAppender('TellMe');
    const serverOptions = {
        ...serverAppenderDefaults,
        ...(window.tellMe.target ?? {})
    };
    if (serverOptions.url) {
        serverAppender.setOptions(serverOptions);
        appenders.push(serverAppender);
    }
}

JL().setOptions({
    appenders: appenders,
    ...loggerDefaults,
    ...(window.tellMe.logger ?? {})
});

JL.setOptions({
    defaultBeforeSend: function defaultBeforeSend(xhr, json) {
        json.loc = document.location.href;
        json.ver = window.toolboxVersion ?? 'unknown';
    },
    requestId: getRequestId(),
    ...(window.tellMe.request ?? {})
})

if (!window.tellMe) {
    window.tellMe = {
        target: {
            url: null
        }
    };
}

window.tellMe.create = function createCustomLogger(name) {
    const logger = JL(name);
    logger.setOptions({
        appenders: appenders,
        ...loggerDefaults,
        ...window.tellMe.logger
    });
    return logger;
}

window.tellMe.log = JL();
